import React from 'react';

const FourOhFour = (data) => {
  console.log(data);
  return (
    <div>
      <div>
        <h1>404!</h1>
      </div>
    </div>
  );
};

export default FourOhFour;
